import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <h1>About</h1>
    <p>
      About in progress. About in progress. About in progress. About in progress. About in progress.
      About in progress. About in progress. About in progress. About in progress. About in progress.{' '}
    </p>
  </Layout>
);

export default AboutPage;
